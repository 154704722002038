// FooterStyle.js
import { css } from '@emotion/react';

export const Footer = css`
    width: 100%;
    margin-top: auto;
`;

export const FooterImage = css`
    width: 100%;
    display: block;
`;